import { graphql, HeadFC, PageProps } from "gatsby"
import { websiteDto } from "../../../utils/website-dto"
import AboutHelper from "../helpers/about-helper"
import { NewHorizonAboutPage } from "../helpers/new-horizon.types"

const About = ({
    data: {
        api: { website, business, user },
    },
}: PageProps<{ api: any }>) => {
    
    const businessPhoneNumber = business?.phones?.[0].phoneNumber;
    const userPhoneNumber = user?.phone?.phoneNumber;
    
    const businessEmail = business?.emails?.[0].email;
    const userEmail = user?.email?.email;
    
    const requiredData = websiteDto({
        ...website,
        teamMembers: website.ourPros,
        email: businessEmail ? businessEmail : userEmail ?? "",
        phoneNumber: businessPhoneNumber ? businessPhoneNumber : userPhoneNumber ?? "",
        hideMyLocation: business?.hideLocation ?? false,
        businessName: business?.displayName ?? "",
        logoUrl: business?.logoImage?.url ?? undefined,
        shortDescription: business?.shortDescription ?? "",
        longDescription: business?.longDescription ?? "",
        qualifications: business?.qualifications ?? [],
        links: business?.links ?? [],
        location: business?.locations && business?.locations.length > 0 ? business?.locations[0] : undefined
    }) as unknown as NewHorizonAboutPage
    return <AboutHelper {...requiredData} />
}
export default About
export const Head: HeadFC = () => <title>About Page</title>

export const query = graphql`
    query ($businessId: String!, $userId: String!) {
        api {
            website(where: { businessId: $businessId }) {
                ...WebsiteInfo
            }
            business(where: { id: $businessId }) {
                ...BusinessInfo
            }
            user(where: { id: $userId }) {
                ...UserInfo
            }
        }
    }
`
